/** @jsx jsx */
import { jsx, Input, Textarea } from 'theme-ui';
import React from 'react';
import { useForm } from 'react-hook-form';
import ContentContainer from '../../gatsby-theme-catalyst-core/components/content-container';

import Layout from '../../gatsby-theme-catalyst-core/components/layout';
const Contact = ({ location, pageContext }) => {
	return (
		<Layout pathname={location.pathname} pageContext={pageContext}>
			<ContentContainer>
				<Form />
			</ContentContainer>
		</Layout>
	);
};

function Form() {
	const { register, handleSubmit, formState: { errors } } = useForm();
	const onSubmit = (data) => console.log(data);

	//console.log(watch("example")); // watch input value by passing the name of it

	return (
		/* "handleSubmit" will validate your inputs before invoking "onSubmit" */
		<div sx={{ maxWidth: '900px', pl: 2, pr: 3 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{/* register your input into the hook by invoking the "register" function */}
				<Input placeholder="E-Mail" {...register('example')} />

				{/* include validation with required or other standard HTML validation rules */}
				<Input placeholder="Name" {...register('exampleRequired', { required: true })} />
				{/* errors will return when field validation fails  */}
				{errors.exampleRequired && <span>This field is required</span>}

				<Textarea sx={{ height: '10em' }} placeholder="Nachricht" />
				<Input
					sx={{
						width: 'auto !important',
						background: '#00f',
						color: '#fff',
						boxShadow: '3px 3px 0 #fff, 3px 3px 0 1px #00f'
					}}
					type="submit"
				/>
			</form>
		</div>
	);
}

export default Contact;
